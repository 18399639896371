import { motion } from 'framer-motion';
import useThemeStore from "../../../stores/useThemeStore";
import useUserIncidentStore from "../../../stores/useUserIncidentStore";
import { useState } from 'react';
import rate_required from '../../../utils/images/require-rate.svg';
import { ReactComponent as Arrow } from '../../../utils/images/arrow_right.svg';

function trimDateTime(dateTimeString) {
    return dateTimeString.split(' ')[0];
}

function getAssignmentText(workgroup, state) {
    return `${state} на ${groupMapping[workgroup]}` || state;
}

const groupMapping = {
    'CS Online': 'группе Технической поддержки',
    'MSP Support': 'группе Технической поддержки',
    'Мероприятия Динамо': 'группе Технической поддержки',
    'Мероприятия Павелецкая': 'группе Технической поддержки',
    'ТП 1я линия': 'группе Технической поддержки',
    'ТП MR': 'группе Технической поддержки',
    'ТП Казань': 'группе Технической поддержки',
    'ТП Красноярск': 'группе Технической поддержки',
    'ТП Павелецкая': 'группе Технической поддержки',
    'ТП Питер': 'группе Технической поддержки',
    'Группа Locker Service': 'группе Поддержки Multispace',
    'Группа инженерных систем': 'группе Поддержки Multispace',
    'Группа мультимедиа систем': 'группе Поддержки Multispace',
    'Группа разработки Multispace': 'группе Поддержки Multispace',
    'Группа сервисов кафе': 'группе Поддержки Multispace',
    'Группа систем безопасности': 'группе Поддержки Multispace',
    'Группа аналитики 1С': 'группе Поддержки 1С',
    'Группа консультантов 1С': 'группе Поддержки 1С',
    'Группа разработки 1С': 'группе Поддержки 1С',
    'Группа стажеров 1С': 'группе Поддержки 1С',
    'Группа базовых ИТ-сервисов': 'группе Системного и сетевого администрирования',
    'Группа вычислительной инфраструктуры': 'группе Системного и сетевого администрирования',
    'Группа сетевого администрирования': 'группе Системного и сетевого администрирования',
    'Группа ИТ Менеджеров': 'группе ИТ Менеджеров',
    'Группа сопровождения web-сервисов': 'группе Сопровождения web-сервисов',
    'Тестовая группа': 'Тестовой группe'
};

function UserIncidentListItem({ incident }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const { setSelectedIncidentUuid, selectedIncidentUuid, setSelectedIncident } = useUserIncidentStore();
    const isSelected = selectedIncidentUuid === incident.linkUuid;
    const [isHovered, setIsHovered] = useState(false);

    return (
        <motion.div
            key={incident.linkUuid}
            className={`user-incident-list__item user-incident-list__item${isSelected ? '_active' : ''}`}
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
            whileHover={{ scale: 1.005 }}
            whileTap={{scale: 0.99}}
            onClick={() => {
                if (selectedIncidentUuid === incident.linkUuid) {
                    setSelectedIncidentUuid(null);
                    setSelectedIncident(null);
                } else {
                    setSelectedIncidentUuid(incident.linkUuid);
                }
            }}
        >
            <div id="first-cell" className="user-incident-list__item__cell">
                <span style={{ fontSize: '16px' }}>№ {incident.number.replace(/^0+/, '')}</span>
                <span style={{ opacity: .6, fontSize: '14px' }}>{trimDateTime(incident.date)}</span>
            </div>
            <div id="second-cell" className="user-incident-list__item__cell">
                <span style={{ fontSize: '16px' }}>{incident.topic.length > 1 ? incident.topic : 'Пустая тема'}</span>
                <span style={{ opacity: .6, fontSize: '14px' }}>{(
                    incident?.state === 'Назначено' ||
                    incident?.state === 'Переназначено' ||
                    incident?.state === 'В работе' ||
                    incident?.state === 'Приостановлено' ||
                    incident?.state === 'На уточнении' ||
                    incident?.state === 'На тестировании' ||
                    incident?.state === 'Передано разработчикам'

                ) ? getAssignmentText(incident?.WorkgroupName, incident?.state) : incident?.state}</span>
            </div>
            {incident?.state === 'Выполнено. Требует подтверждения' && <img style={{ marginLeft: 'auto' }} title="Требуется оценка" src={rate_required} />}
            {((isHovered || isSelected) && incident?.state !== 'Выполнено. Требует подтверждения') && <Arrow style={{ marginLeft: 'auto', opacity: isHovered ? .6 : 1, width: '20px' }} />}
        </motion.div>
    );
}

export default UserIncidentListItem;

// {incident?.state === 'Выполнено. Требует подтверждения' && <img style={{ marginLeft: 'auto' }} title="Требуется оценка" src={rate_required} />}