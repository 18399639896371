import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import { motion } from "framer-motion";
import useThemeStore from "../../stores/useThemeStore";
import useAuthStore from "../../stores/useAuthStore";
import { ReactComponent as BouncingLoading } from '../../utils/images/bouncing-loading.svg';
import errorimg from '../../utils/images/error.svg';

export default function AuthTgPage() {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getUser, user, error, getTokenByTgId, isLoggedIn, status } = useAuthStore();



    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;
            tg.ready();
        }
    }, []);

    useEffect(() => {
        const telegramId = searchParams.get('pc');
        if (window.Telegram && window.Telegram.WebApp) {
            const tg = window.Telegram.WebApp;
            const userData = tg.initDataUnsafe;
            const data = tg.initData;
            console.log(userData);
            console.log(data);
            if (telegramId && !isLoggedIn) {
                Cookies.set('authToken', telegramId, { expires: 0.5 });
                getTokenByTgId(telegramId)
            }
        } else {
            navigate('/sign-in');
        }
    }, [searchParams, isLoggedIn, getTokenByTgId]);

    useEffect(() => {
        console.log('Current status:', status); // Логируем статус

        if (status === 'success' || status === 'error') {
            const timeoutId = setTimeout(() => {
                console.log('Redirecting...'); // Логируем редирект
                navigate(status === 'success' ? '/incidents' : '/sign-in');
            }, 2000);

            return () => {
                console.log('Clearing timeout...'); // Логируем очистку таймера
                clearTimeout(timeoutId);
            };
        }

        if (isLoggedIn) {
            console.log('User is logged in, redirecting to /incidents'); // Логируем редирект
            navigate('/incidents');
        }
    }, [status, navigate, isLoggedIn]);


    const renderContent = () => {
        switch (status) {
            case 'loading':
                return (
                    <>
                        <BouncingLoading style={{ width: '55px' }} alt="Loading..." />
                        <p>Выполняется вход...</p>
                    </>
                );
            case 'error':
                return (
                    <>
                        <motion.img src={errorimg}></motion.img>
                        <div>
                            <p className="error-message">Произошла ошибка</p>
                            <p style={{ fontWeight: 'bold' }}>{error?.message}</p>
                        </div>
                    </>
                );
            case 'success':
                return (
                    <>
                        <svg className="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                            <circle className="circle" fill="#5bb543" cx="24" cy="24" r="22" />
                            <path className="tick" fill="none" stroke="#FFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M14 27l5.917 4.917L34 17" />
                        </svg>
                        <div>
                            <p className="success-message">Добро пожаловать,</p>
                            <p className="success-message" style={{ fontWeight: 'bold' }}>{user.fullName}!</p>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50, transition: .3 }}
                transition={{ duration: 0.5 }}
                className={`auth-page__block auth-page__block${colorSchemeClassName}`}>
                {renderContent()}
            </motion.div>
        </div>
    );
}