import { useLocation } from "react-router-dom";
import usePopupStore from "../../../../stores/usePopupStore";
import useThemeStore from "../../../../stores/useThemeStore";
import { Services } from "../ServiceConstants";
import { motion } from "framer-motion";
import StyledDropzone from "../../../../components/FileInput/FileInput";
import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import PhoneInput from "../../../../components/PhoneInput/PhoneInput";
import axios from "axios";
import { defaultHeaders } from "../../../../utils/baseUrlApi";
import InputMask from "react-input-mask";
import DefaultSelect from "../../../../components/Select/Select";
import ReactCalendar from "../../../../components/ReactCalendar/ReactCalendar";
import Cookies from 'js-cookie';

const getAuthHeaders = () => ({
    "Content-Type": "application/json",
    "Authorization": `Basic ${Cookies.get('authToken')}`
}); 

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const blockTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.2, type: 'Inertia', delay: 0.2 },
};


function NumberRedirectForm({ setCurrentStep, calendarRef }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = useMemo(
        () => `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`,
        [colorScheme]
    );

    const query = useQuery();
    const serviceComponentUuid = query.get("serviceComponentUuid");
    const serviceUuid = query.get("serviceUuid");

    const [filesArray, setFileBase64List] = useState([]);
    const [phoneNumberValue, setPhoneNumberValue] = useState('');
    const [redirectFrom, setRedirectFrom] = useState('');
    const [redirectTo, setRedirectTo] = useState('');
    const [redirectionType, setRedirectionType] = useState('');
    const [startDate, setStartDate] = useState(null);

    const [isCalendarOpened, setCalendarOpened] = useState(true);

    useEffect(() => {
        setCalendarOpened(false);
    }, [startDate]);

    const {
        serviceComponents,

        setIsSuccess,
        setIsError,
        setIsComponentLoading,
        isComponentsLoading,
    } = usePopupStore();




    const selectedService = Services.find((item) => item.ServiceUuid === serviceUuid);
    const selectedServiceComponent = serviceComponents?.find((item) => item.ServiceComponentUuid === serviceComponentUuid);

    const generateDescription = () => {
        return `Здравствуйте! Мне необходимо ${redirectionType?.value?.toLowerCase() || 'подключить'} переадресацию с номера ${redirectFrom || '+7 (999) 999-99-99'} на номер ${redirectTo || '+7 (999) 999-99-99'} начиная с ${startDate?.toLocaleDateString() || '25.11.2024'}`
    };

    const description = useMemo(() => {
        const descriptionParts = [
            generateDescription(),
            phoneNumberValue.length > 1 ? `Контактный номер телефона: ${phoneNumberValue}` : '',
        ];
        return descriptionParts.filter(Boolean).join('\n');
    }, [phoneNumberValue, redirectFrom, redirectTo, startDate, redirectionType]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsComponentLoading(true);
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mobiledatag/addNewIncedentM`,
                {
                    Topic: selectedServiceComponent?.ServiceComponent,
                    Data: "12.12.2023 12:13:14",
                    Description: description,
                    MembershipServices: {
                        UID: serviceComponentUuid,
                        Service: {
                            UID: serviceUuid
                        }
                    },
                    Files: filesArray
                },
                {
                    headers: getAuthHeaders()
                }
            );
            setIsSuccess(true);
        } catch (error) {
            console.error("Error submitting form:", error);
            setIsError(true);
        } finally {
            setCurrentStep(3);
            setIsComponentLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', marginTop: '15px' }}>
            <motion.form
                {...blockTransition}
                className={`popup__form popup__form${colorSchemeClassName}`}
                onSubmit={handleFormSubmit}
            >
                <div className="popup__form__text-block">
                    <p style={{ fontWeight: 'bold' }}>{`${selectedService?.Service} — ${selectedServiceComponent?.ServiceComponent}`}</p>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <div className="popup__form__placeholder-div">
                        <p>Что необходимо сделать</p>
                        <DefaultSelect
                            placeholder={'Выберите'}
                            isSearchable={false}
                            options={[
                                { value: 'Подключить', label: 'Подключить' },
                                { value: 'Отключить', label: 'Отключить' }
                            ]}
                            value={redirectionType}
                            onChange={(selectedOption) => setRedirectionType(selectedOption)}
                            required={true}
                        />
                    </div>
                    <div className="popup__form__placeholder-div">
                        <p>С этого номера</p>
                        <InputMask
                            placeholder={'+7 (926) 763-34-56'}
                            mask='+7 (999) 999-99-99'
                            className='popup__form__input'
                            value={redirectFrom}
                            onChange={(e) => setRedirectFrom(e.target.value)}
                            title="Номер телефона полностью"
                            required
                        />

                    </div>
                    <div className="popup__form__placeholder-div">
                        <p>На этот номер</p>
                        <InputMask
                            placeholder={'+7 (926) 763-34-56'}
                            className='popup__form__input'
                            mask='+7 (999) 999-99-99'
                            value={redirectTo}
                            onChange={(e) => setRedirectTo(e.target.value)}
                            title="Номер телефона полностью"
                            required
                        />
                    </div>
                    <div className="popup__form__placeholder-div">
                        <p>Когда необходимо</p>
                        <div style={{ color: startDate ? 'var(--font-color)' : 'var(--font-color-tr)' }} className="popup__form__calendar-div" onClick={() => setCalendarOpened(true)}>{startDate?.toLocaleDateString() || '25.11.2024'}</div>
                        <ReactCalendar isOpened={isCalendarOpened} setCalendarOpened={setCalendarOpened} value={startDate} onChange={setStartDate} />
                    </div>
                </div>
                <div className="popup__form__redirect-p">
                    <p>
                        Здравствуйте! Мне необходимо
                        <span style={{ fontWeight: 'bold', color: 'var(--header-menu-icon-color)' }}> {redirectionType?.value?.toLowerCase() || 'подключить'} </span>
                        переадресацию<br></br> с номера <span style={{ fontWeight: 'bold', color: 'var(--header-menu-icon-color)' }}>{redirectFrom || '+7 (999) 999-99-99'} </span>
                        на номер <span style={{ fontWeight: 'bold', color: 'var(--header-menu-icon-color)' }}>{redirectTo || '+7 (999) 999-99-99'}</span> <br></br>начиная с
                        <span style={{ fontWeight: 'bold', color: 'var(--header-menu-icon-color)' }}> {startDate?.toLocaleDateString() || '25.11.2024'}</span>
                    </p>
                </div>
                <PhoneInput setPhoneNumber={setPhoneNumberValue} phoneNumberValue={phoneNumberValue} />

                <div className="popup__form__button-block">
                    <StyledDropzone setFilesArray={setFileBase64List} />
                    <button className="popup__form__submit-button" type="submit" disabled={isComponentsLoading}>Отправить</button>
                </div>
            </motion.form >
        </div >
    );
}

export default NumberRedirectForm;
