import { useLocation } from "react-router-dom";
import usePopupStore from "../../../../stores/usePopupStore";
import useThemeStore from "../../../../stores/useThemeStore";
import { Services } from "../ServiceConstants";
import { motion } from "framer-motion";
import StyledDropzone from "../../../../components/FileInput/FileInput";
import { useEffect, useState } from "react";
import PhoneInput from "../../../../components/PhoneInput/PhoneInput";
import Cookies from 'js-cookie';
import axios from "axios";
import { defaultHeaders } from "../../../../utils/baseUrlApi";
import { ReactComponent as CPOArrow } from '../../../../utils/images/arrow-cpo.svg';
import { ReactComponent as CPOButton } from '../../../../utils/images/cpo-button.svg';
import guide from '../../../../utils/files/Установка приложений из ЦПО.pdf';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const getAuthHeaders = () => ({
    "Content-Type": "application/json",
    "Authorization": `Basic ${Cookies.get('authToken')}`
}); 

const blockTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.2, type: 'Inertia', delay: 0.2 },
};

function PCVPNSetup({ setCurrentStep }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const query = useQuery();
    const serviceComponentUuid = query.get("serviceComponentUuid");
    const serviceUuid = query.get("serviceUuid");

    const [filesArray, setFileBase64List] = useState([]);
    const [textareaValue, setTextareaValue] = useState('');
    const [phoneNumberValue, setPhoneNumberValue] = useState('');
    const [isChecked, setIsChecked] = useState(false)
    const [softwareName, setSoftwareName] = useState('');
    const [person, setPerson] = useState('');
    const [link, setLink] = useState('');

    const {
        serviceComponents,
        isSuccess,
        isError,
        setIsSuccess,
        setIsError,
        setIsComponentLoading,
        isComponentsLoading,
    } = usePopupStore();


    const selectedService = Services?.find((item) => item.ServiceUuid === serviceUuid);

    const selectedServiceComponent = serviceComponents?.find((item) => item.ServiceComponentUuid === serviceComponentUuid);

    const incidentDescription = phoneNumberValue.length > 1 ? `${textareaValue}\nКонтактный номер телефона: ${phoneNumberValue}` : textareaValue;

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsComponentLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mobiledatag/addNewIncedentM`,
                {
                    Topic: selectedServiceComponent?.ServiceComponent,
                    Data: "12.12.2023 12:13:14",
                    Description: incidentDescription,
                    MembershipServices: {
                        UID: serviceComponentUuid,
                        Service: {
                            UID: serviceUuid
                        }
                    },
                    Files: filesArray
                },
                {
                    headers: getAuthHeaders()
                });
            setIsSuccess(true);
        } catch (error) {
            console.log(error);
            setIsError(true);
        } finally {
            setCurrentStep(3);
            setIsComponentLoading(false);
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', marginTop: '15px' }}>
            <motion.form
                initial={blockTransition.initial}
                animate={blockTransition.animate}
                exit={blockTransition.exit}
                transition={blockTransition.transition}
                className={`popup__form popup__form${colorSchemeClassName}`}
                onSubmit={handleFormSubmit}
            >
                <div className="popup__form__text-block">
                    <p style={{ fontWeight: 'bold' }}>{`${selectedService?.Service} — ${selectedServiceComponent?.ServiceComponent}`}</p>
                </div>
                <div className="popup__form__vpn-block">
                    <p>Для настройки подключения к корпоративному VPN <br></br>
                    воспользуйтесь инструкцией <a href="/knowledge?categorie=VPN" target="_blank">Настройка VPN</a> для Windows или macOS.</p>
                    <label style={{ marginTop: '10px' }}>
                        <input checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} type="checkbox"></input>
                        Инструкция не помогает
                    </label>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '100%', height: '100%', gap: '10px' }}>
                    <p>Описание:</p>
                    <textarea
                        className={`popup__textarea popup__textarea${colorSchemeClassName}`}
                        placeholder="Подробно опишите, с чем Вам нужна помощь..."
                        required
                        value={textareaValue}
                        onChange={(e) => setTextareaValue(e.target.value)}
                        disabled={!isChecked}
                    >
                    </textarea>
                </div>
                <PhoneInput setPhoneNumber={setPhoneNumberValue} phoneNumberValue={phoneNumberValue} />
                <div className="popup__form__button-block">
                    <StyledDropzone setFilesArray={setFileBase64List} />
                    <button className="popup__form__submit-button" type="submit" disabled={isComponentsLoading || !isChecked}>Отправить</button>
                </div>
            </motion.form>
        </div>
    );
}

export default PCVPNSetup;
