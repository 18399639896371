import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import KnowledgeTableItem from './KnowledgeTableItem';
import '../KnowledgePage.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PdfPopup from './PdfPopup';
import KnowledgeSearch from '../KnowledgeSearch/KnowledgeSearchBar';
import changePassword from '../../../utils/images/changePassword.webp';
import mailAndroid from '../../../utils/images/mailAndroid.webp';
import mailiOS from '../../../utils/images/mailiOS.webp';
import vpnMacOS from '../../../utils/images/vpnMacOS.webp';
import vpnWindows from '../../../utils/images/vpnWindows.webp';
import telegramHacked from '../../../utils/images/telegramHacked.webp';
import telegramCloned from '../../../utils/images/telegramCloned.webp';
import mobileNetwork from '../../../utils/images/mobileNetwork.webp';
import fixMailClientPasswordError from '../../../utils/images/fixMailClientPasswordError.webp';
const guidesList = [
    {
        title: 'Смена пароля учетной записи',
        description: 'Как изменить пароль своей корпоративной учетной записи',
        date: '11.03.2025',
        readTime: 3,
        link: '/files/changeAccountPassword.pdf',
        categoryName: 'Безопасность',
        image: changePassword
    },
    {
        title: 'Решение повторного запроса пароля в почте',
        description: 'Исправление повторного запроса пароля, при работе с почтой',
        date: '17.09.2024',
        readTime: 3,
        link: '/files/fixMailClientPasswordError.pdf',
        categoryName: 'Почта',
        image: fixMailClientPasswordError
    },
    {
        title: 'Настройка почты на Android',
        description: 'Как настроить корпоративную почту на\u00A0смартфоне под управлением Android',
        date: '25.07.2024',
        readTime: 5,
        link: '/files/mailAndroid.pdf',
        categoryName: 'Почта',
        image: mailAndroid
    },
    {
        title: 'Настройка почты на iOS',
        description: 'Как настроить корпоративную почту на\u00A0iPhone',
        date: '25.07.2024',
        readTime: 5,
        link: '/files/mailIos.pdf',
        categoryName: 'Почта',
        image: mailiOS
    },
    {
        title: 'Настройка VPN на macOS',
        description: 'Как настроить корпоративный VPN на\u00A0компьютере под управлением macOS',
        date: '11.03.2025',
        readTime: 5,
        link: '/files/vpnMacOS.pdf',
        categoryName: 'VPN',
        image: vpnMacOS
    },
    {
        title: 'Настройка VPN на Windows',
        description: 'Как настроить корпоративный VPN на\u00A0компьютере под управлением Windows',
        date: '11.03.2025',
        readTime: 5,
        link: '/files/vpnWindows.pdf',
        categoryName: 'VPN',
        image: vpnWindows
    },
    {
        title: 'Памятка по корпоративной связи',
        description: 'Информация о\u00A0предоставлении корпоративной связи в\u00A0компании',
        date: '08.11.2024',
        readTime: 3,
        link: '/files/corpSimcard.pdf',
        categoryName: 'Корпоративная связь',
        image: mobileNetwork
    },
    {
        title: 'Что делать, если ваш Telegram взломали',
        description: 'Рекомендации к\u00A0действиям при взломе вашего аккаунта в\u00A0Telegram',
        date: '29.10.2024',
        readTime: 7,
        link: '/files/telegramHacked.pdf',
        categoryName: 'Telegram',
        image: telegramHacked
    },
    {
        title: 'Что делать, если ваш Telegram клонировали',
        description: 'Рекомендации к\u00A0действиям при клонировании вашего аккаунта в\u00A0Telegram',
        date: '29.10.2024',
        readTime: 3,
        link: '/files/telegramCloned.pdf',
        categoryName: 'Telegram',
        image: telegramCloned
    },
];


function KnowledgeTable() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('Все');
    const location = useLocation();
    const navigate = useNavigate();

    const openPopup = (pdfFile, title) => {
        setSelectedPdf(pdfFile);
        setIsOpen(true);
        navigate(`?guide=${encodeURIComponent(title)}`);
    };

    const closePopup = () => {
        setIsOpen(false);
        setSelectedPdf('');
        navigate('/knowledge');
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const guideTitle = params.get('guide');

        if (guideTitle) {
            const guide = guidesList.find(item => item.title === decodeURIComponent(guideTitle));
            if (guide) {
                openPopup(guide.link, guide.title);
            } else {
                console.error('Guide not found for title:', guideTitle);
            }
        }
    }, [location.search]);

    const filteredGuides = guidesList.filter(item => {
        const matchesCategory = selectedCategory === 'Все' || item.categoryName === selectedCategory;
        const matchesSearch = item.title.toLowerCase().includes((searchQuery || '').toLowerCase());
        return matchesCategory && matchesSearch;
    });

    return (
        <>
            <KnowledgeSearch
                setSearchQuery={setSearchQuery}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
                guidesList={guidesList}
            />
            <div className='knowledge-table__overlay'>
                {filteredGuides.length > 0 ? (
                    <OverlayScrollbarsComponent
                        options={{ scrollbars: { autoHide: 'leave', visibility: 'none', margin: '0px' } }}
                        style={{padding: '0px 0px 10px 0px'}}
                    >
                        <div className='knowledge-table'>
                            {filteredGuides.map((item) => (
                                <KnowledgeTableItem
                                    layoutId={`item-${selectedPdf}`}
                                    key={`title_${item.title}`}
                                    title={item.title}
                                    link={item.link}
                                    readTime={item.readTime}
                                    image={item.image}
                                    date={item.date}
                                    description={item.description}
                                    onClick={() => openPopup(item.link, item.title)}
                                />
                            ))}
                        </div>
                    </OverlayScrollbarsComponent>

                ) : (
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <p className="no-results">Ничего не найдено</p>
                    </div>
                )}
                <PdfPopup isOpen={isOpen} setIsOpen={setIsOpen} closePopup={closePopup} selectedPdf={selectedPdf} />
            </div>
        </>
    );
}

export default KnowledgeTable;

