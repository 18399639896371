import { useLocation } from "react-router-dom";
import usePopupStore from "../../../../stores/usePopupStore";
import useThemeStore from "../../../../stores/useThemeStore";
import { Services } from "../ServiceConstants";
import { motion } from "framer-motion";
import StyledDropzone from "../../../../components/FileInput/FileInput";
import { useEffect, useState } from "react";
import PhoneInput from "../../../../components/PhoneInput/PhoneInput";
import Cookies from 'js-cookie';
import axios from "axios";
import { defaultHeaders } from "../../../../utils/baseUrlApi";
import { ReactComponent as CPOArrow } from '../../../../utils/images/arrow-cpo.svg';
import { ReactComponent as CPOButton } from '../../../../utils/images/cpo-button.svg';
import guide from '../../../../utils/files/Установка приложений из ЦПО.pdf';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const getAuthHeaders = () => ({
    "Content-Type": "application/json",
    "Authorization": `Basic ${Cookies.get('authToken')}`
}); 

const blockTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.2, type: 'Inertia', delay: 0.2 },
};

function CPOForm({ setCurrentStep }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const query = useQuery();
    const serviceComponentUuid = query.get("serviceComponentUuid");
    const serviceUuid = query.get("serviceUuid");

    const [filesArray, setFileBase64List] = useState([]);
    const [textareaValue, setTextareaValue] = useState('');
    const [phoneNumberValue, setPhoneNumberValue] = useState('');
    const [isChecked, setIsChecked] = useState(false)
    const [softwareName, setSoftwareName] = useState('');
    const [person, setPerson] = useState('');
    const [link, setLink] = useState('');

    const {
        serviceComponents,
        isSuccess,
        isError,
        setIsSuccess,
        setIsError,
        setIsComponentLoading,
        isComponentsLoading,
    } = usePopupStore();
    const { iniciatorUuid } = JSON.parse(Cookies.get('user'));

    const selectedService = Services?.find((item) => item.ServiceUuid === serviceUuid);

    const selectedServiceComponent = serviceComponents?.find((item) => item.ServiceComponentUuid === serviceComponentUuid);

    const incidentDescription = phoneNumberValue.length > 1 ? `${textareaValue}\nКонтактный номер телефона: ${phoneNumberValue}` : textareaValue;

    const descriptionParts = [
        textareaValue,
        phoneNumberValue?.length > 1 ? `Контактный номер телефона: ${phoneNumberValue}` : '',
        `Название ПО: ${softwareName}\nКому установить: ${person}\nСсылка на дистрибутив: ${link}\n`
    ];

    const description = descriptionParts.filter(Boolean).join('\n');

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsComponentLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mobiledatag/addNewIncedentM`,
                {
                    Topic: selectedServiceComponent?.ServiceComponent,
                    Data: "12.12.2023 12:13:14",
                    Description: description,
                    MembershipServices: {
                        UID: serviceComponentUuid,
                        Service: {
                            UID: serviceUuid
                        }
                    },
                    Files: filesArray
                },
                {
                    headers: getAuthHeaders()
                });
            setIsSuccess(true);
        } catch (error) {
            console.log(error);
            setIsError(true);
        } finally {
            setCurrentStep(3);
            setIsComponentLoading(false);
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', marginTop: '15px' }}>
            <motion.form
                initial={blockTransition.initial}
                animate={blockTransition.animate}
                exit={blockTransition.exit}
                transition={blockTransition.transition}
                className={`popup__form popup__form${colorSchemeClassName}`}
                onSubmit={handleFormSubmit}
            >
                <div className="popup__form__text-block">
                    <p style={{ fontWeight: 'bold' }}>{`${selectedService?.Service} — ${selectedServiceComponent?.ServiceComponent}`}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div className="popup__form__cpo-button-block">
                        <p>Прежде, чем создавать обращение убедитесь, что программное обеспечение, которое Вы хотите установить,
                            или его аналог отсутствует в ЦПО, поскольку всё необходимое для работы ПО уже доступно для установки
                            в Центре программного обеспечения (ЦПО) на Вашем компьютере.</p>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                            <a href={guide} target='_blank' rel='noreferrer'>Инструкция по установке ПО из ЦПО</a>
                            <button className="popup__form__button-cpo" type="button" onClick={() => { window.open('softwarecenter:') }}>
                                Открыть ЦПО
                            </button>
                        </div>

                    </div>
                    <div className="popup__form__cpo-input-block">
                        <label>
                            <input checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} type="checkbox"></input>
                            Я убедился, что нужного ПО нет в ЦПО
                        </label>
                        <div className="popup__form__cpo-input-row">
                            <input
                                className="popup__form__input"
                                onChange={(e) => setSoftwareName(e.target.value)}
                                placeholder="Название ПО" disabled={isChecked ? false : true}
                                required></input>

                            <input
                                className="popup__form__input"
                                onChange={(e) => setLink(e.target.value)}
                                placeholder="Ссылка на установщик"
                                disabled={isChecked ? false : true}
                                required></input>
                        </div>
                        <div className="popup__form__cpo-input-row">
                            <input
                                className="popup__form__input"
                                onChange={(e) => setPerson(e.target.value)}
                                placeholder="Кому установить" disabled={isChecked ? false : true}
                                required></input>
                            <input
                                className="popup__form__input"
                                onChange={(e) => setTextareaValue(e.target.value)}
                                placeholder="Обоснуйте необходимость установки"
                                disabled={isChecked ? false : true}
                                required></input>
                        </div>
                    </div>

                </div>
                <PhoneInput setPhoneNumber={setPhoneNumberValue} phoneNumberValue={phoneNumberValue} />
                <div className="popup__form__button-block">
                    <StyledDropzone setFilesArray={setFileBase64List} />
                    <button className="popup__form__submit-button" type="submit" disabled={isComponentsLoading || !isChecked}>Отправить</button>
                </div>
            </motion.form>
        </div>
    );
}

export default CPOForm;
