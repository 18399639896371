import { useLocation } from "react-router-dom";
import usePopupStore from "../../../../stores/usePopupStore";
import useThemeStore from "../../../../stores/useThemeStore";
import { Services } from "../ServiceConstants";
import { motion } from "framer-motion";
import StyledDropzone from "../../../../components/FileInput/FileInput";
import { useEffect, useState } from "react";
import PhoneInput from "../../../../components/PhoneInput/PhoneInput";
import axios from "axios";
import { Tooltip } from 'react-tooltip';
import { ReactComponent as Info } from '../../../../utils/images/info.svg';
import DefaultSelect from "../../../../components/Select/Select";
import Cookies from 'js-cookie';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const getAuthHeaders = () => ({
    "Content-Type": "application/json",
    "Authorization": `Basic ${Cookies.get('authToken')}`
});

const blockTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.2, type: 'Inertia', delay: 0.2 },
};

function Rework1CForm({ setCurrentStep }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const query = useQuery();
    const serviceComponentUuid = query.get("serviceComponentUuid");
    const serviceUuid = query.get("serviceUuid");

    const [filesArray, setFileBase64List] = useState([]);
    const [textareaValue, setTextareaValue] = useState('');
    const [phoneNumberValue, setPhoneNumberValue] = useState('');

    const [finMethriksOptions, setFinMethriksOptions] = useState([]);
    const [uprRiskMethriksOptions, setUprRiskMethriksOptions] = useState([]);
    const [ohvatMethriksOptions, setOhvatMethriksOptions] = useState([]);
    const [interesantMethriksOptions, setInteresantMethriksOptions] = useState([]);
    const [methriksDescription, setMethriksDescription] = useState('');
    const [incidentTopic, setIncidentTopic] = useState('');
    const [selectedOptions, setSelectedOptions] = useState({
        FinmethriksUuid: null,
        UprRiskmethriksUuid: null,
        OhvatmethriksUuid: null,
        InteresantmethriksUuid: null
    });

    const fetchOptions = async () => {
        try {
            const token = localStorage.getItem('jwt');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/mobiledatag/GetMetriks`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Basic ${Cookies.get('authToken')}`
                }
            });
            if (response.status === 401) {
                return null;
            }
            const res = await response.json();
            setFinMethriksOptions(res.Fin_methriks.map(option => ({
                value: option.FinmethriksUuid,
                label: option.Finmethriks
            })));
            setUprRiskMethriksOptions(res.UprRisk_methriks.map(option => ({
                value: option.UprRiskmethriksUuid,
                label: option.UprRiskmethriks
            })));
            setOhvatMethriksOptions(res.Ohvat_methriks.map(option => ({
                value: option.OhvatmethriksUuid,
                label: option.Ohvatkmethriks
            })));
            setInteresantMethriksOptions(res.Interesant_methriks.map(option => ({
                value: option.InteresantmethriksUuid,
                label: option.Interesantmethriks
            })));
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    useEffect(() => {
        fetchOptions();
    }, []);

    const {
        serviceComponents,
        isSuccess,
        isError,
        setIsSuccess,
        setIsError,
        setIsComponentLoading,
        isComponentsLoading,
    } = usePopupStore();

    const selectedService = Services?.find((item) => item.ServiceUuid === serviceUuid);
    const selectedServiceComponent = serviceComponents?.find((item) => item.ServiceComponentUuid === serviceComponentUuid);

    const incidentDescription = phoneNumberValue.length > 1 ? `${textareaValue}\nКонтактный номер телефона: ${phoneNumberValue}` : textareaValue;

    const handleSelectChange = (value, key) => {
        setSelectedOptions(prevOptions => ({
            ...prevOptions,
            [key]: value
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsComponentLoading(true);
            console.log({
                Topic: incidentTopic,
                Data: "12.12.2023 12:13:14",
                Description: incidentDescription,
                MembershipServices: {
                    UID: serviceComponentUuid,
                    Service: {
                        UID: serviceUuid
                    }
                },
                "FinmethriksUuid": selectedOptions.FinmethriksUuid.value,
                "UprRiskmethriksUuid": selectedOptions.UprRiskmethriksUuid.value,
                "OhvatmethriksUuid": selectedOptions.OhvatmethriksUuid.value,
                "InteresantmethriksUuid": selectedOptions.InteresantmethriksUuid.value,
                "IniciatorsComm": methriksDescription,
                Files: filesArray
            });
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mobiledatag/addNewIncedentM`,
                {
                    Topic: incidentTopic,
                    Data: "12.12.2023 12:13:14",
                    Description: incidentDescription,
                    MembershipServices: {
                        UID: serviceComponentUuid,
                        Service: {
                            UID: serviceUuid
                        }
                    },
                    "FinmethriksUuid": selectedOptions.FinmethriksUuid.value,
                    "UprRiskmethriksUuid": selectedOptions.UprRiskmethriksUuid.value,
                    "OhvatmethriksUuid": selectedOptions.OhvatmethriksUuid.value,
                    "InteresantmethriksUuid": selectedOptions.InteresantmethriksUuid.value,
                    "IniciatorsComm": methriksDescription,
                    Files: filesArray
                },
                {
                    headers: getAuthHeaders()
                });
            setIsSuccess(true);
        } catch (error) {
            console.error(error);
            setIsError(true);
        } finally {
            setCurrentStep(3);
            setIsComponentLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', marginTop: '15px' }}>
            <motion.form
                initial={blockTransition.initial}
                animate={blockTransition.animate}
                exit={blockTransition.exit}
                transition={blockTransition.transition}
                className={`popup__form popup__form${colorSchemeClassName}`}
                onSubmit={handleFormSubmit}
            >
                <div className="popup__form__text-block">
                    <p style={{ fontWeight: 'bold' }}>{`${selectedService?.Service} — ${selectedServiceComponent?.ServiceComponent}`}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%' }}>
                    <div className="popup__form__1c-block">
                        <div className='incident-popup__select-row'>
                            <DefaultSelect
                                placeholder={'Выберите финансовую метрику '}
                                isSearchable={false}
                                options={finMethriksOptions}
                                required={true}
                                onChange={(value) => handleSelectChange(value, "FinmethriksUuid")}
                            />
                            <Info id="finance-metrik" />
                            <Tooltip className="popup-tooltip" anchorSelect='#finance-metrik'>Метрика показывает какую прибыль или какую финансовую экономию получит компания в случае реализации обращения. Перед установкой данной метрики необходимо произвести обобщенный экономический расчет. Если Вы не знаете экономического эффекта, то устанавливается метрика «0 руб. или не определено».</Tooltip>
                        </div>
                        <div className='incident-popup__select-row'>
                            <DefaultSelect
                                placeholder={'Выберите метрику риска'}
                                isSearchable={false}
                                options={uprRiskMethriksOptions}
                                required={true}
                                onChange={(value) => handleSelectChange(value, "UprRiskmethriksUuid")}
                            />
                            <Info id="upr-metrik" />
                            <Tooltip className="popup-tooltip" anchorSelect='#upr-metrik'>Метрика показывает какой риск может возникнуть у всей компании, в случае когда обращение НЕ будет реализовано. Административная ответственность – это штрафы, которые могут быть наложены на компании. Изменение законодательства – вышел новый закон которые влияет на бизнес-процесс компании. Корпоративный – пояснения в самой метрике. Уголовная ответственность/приостановка деятельности – в результате НЕ реализации обращения возможна приостановка деятельности компании или уголовная ответственность руководства компании. В любом другом случае Вы устанавливаете метрику «Риск неизвестен или отсутствует»</Tooltip>
                        </div>
                        <div className='incident-popup__select-row'>
                            <DefaultSelect
                                placeholder={'Выберите метрику охвата'}
                                isSearchable={false}
                                options={ohvatMethriksOptions}
                                required={true}
                                onChange={(value) => handleSelectChange(value, "OhvatmethriksUuid")}
                            />
                            <Info id="okhvat-metrik" />
                            <Tooltip className="popup-tooltip" anchorSelect='#okhvat-metrik'>Метрика показывает насколько широко будет использоваться доработка по Вашему обращению. В случае, когда доработкой будет пользоваться один человек, один отдел или несколько отделов – вы устанавливаете соответствующую метрику. Метрика «компания в целом» устанавливается в случае, когда доработка охватывает все подразделения компании или весь персонал компании.</Tooltip>
                        </div>
                        <div className='incident-popup__select-row'>
                            <DefaultSelect
                                placeholder={'Выберите метрику интересанта'}
                                isSearchable={false}
                                options={interesantMethriksOptions}
                                required={true}
                                onChange={(value) => handleSelectChange(value, "InteresantmethriksUuid")}
                            />
                            <Info id="interesant-metrik" />
                            <Tooltip className="popup-tooltip" anchorSelect='#interesant-metrik'>Здесь Вы должны указать конечного интересанта данного обращения. Метрики «Глава БЮ» или «Совет директоров» устанавливается в том случае, когда инициатива доработки в системе 1С исходит непосредственно от БЮ или члена Совета директоров. Метрика «Гос.органы» устанавливается в случае если информация полученная в результате доработки по данному обращению будет передана в государственные структуры (ФНС, МВД, ФСБ, ФТС и т.д.).</Tooltip>
                        </div>
                    </div>
                    <div className="popup__form__1c-block">
                        <input
                            className="popup__form__input"
                            placeholder="Тема"
                            value={incidentTopic}
                            onChange={(e) => setIncidentTopic(e.target.value)}
                        >
                        </input>
                        <textarea
                            className={`popup__textarea popup__textarea${colorSchemeClassName}`}
                            placeholder="Комментарий к метрикам"
                            required
                            value={methriksDescription}
                            onChange={(e) => setMethriksDescription(e.target.value)}
                        >
                        </textarea>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '100%', height: '100%', gap: '10px' }}>
                    <p>Описание:</p>
                    <textarea
                        className={`popup__textarea popup__textarea${colorSchemeClassName}`}
                        placeholder="Описание обращения..."
                        value={textareaValue}
                        onChange={(e) => setTextareaValue(e.target.value)}
                    >
                    </textarea>
                </div>
                <PhoneInput setPhoneNumber={setPhoneNumberValue} phoneNumberValue={phoneNumberValue} />
                <div className="popup__form__button-block">
                    <StyledDropzone setFilesArray={setFileBase64List} />
                    <button className="popup__form__submit-button" type="submit" disabled={isComponentsLoading}>Отправить</button>
                </div>
            </motion.form>
        </div>
    );
}

export default Rework1CForm;
