import { useLocation } from "react-router-dom";
import usePopupStore from "../../../../stores/usePopupStore";
import useThemeStore from "../../../../stores/useThemeStore";
import { Services } from "../ServiceConstants";
import { motion } from "framer-motion";
import StyledDropzone from "../../../../components/FileInput/FileInput";
import { useEffect, useState } from "react";
import PhoneInput from "../../../../components/PhoneInput/PhoneInput";
import Cookies from 'js-cookie';
import axios from "axios";
import { defaultHeaders } from "../../../../utils/baseUrlApi";
import DefaultSelect from "../../../../components/Select/Select";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const getAuthHeaders = () => ({
    "Content-Type": "application/json",
    "Authorization": `Basic ${Cookies.get('authToken')}`
}); 

const blockTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.2, type: 'Inertia', delay: 0.2 },
};

function LocationForm({ setCurrentStep }) {
    const colorScheme = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const query = useQuery();
    const serviceComponentUuid = query.get("serviceComponentUuid");
    const serviceUuid = query.get("serviceUuid");

    const [filesArray, setFileBase64List] = useState([]);
    const [textareaValue, setTextareaValue] = useState('');
    const [phoneNumberValue, setPhoneNumberValue] = useState('');

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null)
    const [selectedType, setSelectedType] = useState(null);

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
        setSelectedPlace('');
    };

    const handlePlaceChange = (selectedOption) => {
        setSelectedPlace(selectedOption);
    };

    const cities = [
        { value: 'Москва', label: 'Москва' },
        { value: 'Санкт-Петербург', label: 'Санкт-Петербург' },
        { value: 'Казань', label: 'Казань' },
        { value: 'Красноярск', label: 'Красноярск' },
        { value: 'Горно-Алтайск', label: 'Горно-Алтайск' }
    ];

    const PhysicalOrRemote = [
        { value: 'Офис или Объект', label: 'Офис или Объект' },
        { value: 'Удалённо', label: 'Удалённо' }
    ];


    const placesByCity = {
        'Москва': [
            { value: 'Multipsace Павелецкая', label: 'Multipsace Павелецкая' },
            { value: 'Multispace Динамо', label: 'Multispace Динамо' },
            { value: 'Multispace Актер', label: 'Multispace Актер' },
            { value: 'СБЕР А ', label: 'СБЕР А ' },
            { value: 'ПСБ', label: 'ПСБ' },
            { value: 'Балчуг', label: 'Балчуг' },
            { value: 'Тинькофф', label: 'Тинькофф' },
            { value: 'IСity', label: 'IСity' },
            { value: 'Сколково Школа', label: 'Сколково Школа' },
            { value: 'Сколково Мегацод', label: 'Сколково Мегацод' },
            { value: 'Сколково Автобан', label: 'Сколково Автобан' },
            { value: 'Позитив', label: 'Позитив' },
            { value: 'Прорыв', label: 'Прорыв' },
            { value: 'Росатом', label: 'Росатом' },
            { value: 'Центр найма ', label: 'Центр найма ' },
            { value: 'Росбанк ОКО II (11 этаж)', label: 'Росбанк ОКО II (11 этаж)' },
            { value: 'Азовская 15А', label: 'Азовская 15А' },
            { value: 'Другое', label: 'Другое' },
        ],
        'Санкт-Петербург': [
            { value: 'Лахта', label: 'Лахта' },
            { value: 'Чкаловская', label: 'Чкаловская' },
            { value: 'Multispace Pravda ', label: 'Multispace Pravda' },
            { value: 'Т-Банк', label: 'Т-банк' },
            { value: 'Другое', label: 'Другое' },
        ],
        'Казань': [
            { value: 'Офис', label: 'Офис' },
            { value: 'Другое', label: 'Другое' },
        ],
        'Горно-Алтайск': [
            { value: 'Манжерок', label: 'Манжерок' },
            { value: 'Другое', label: 'Другое' },
        ],
        'Красноярск': [
            { value: 'Красноярск', label: 'РусГидро' },
            { value: 'Другое', label: 'Другое' },
        ],
    };



    const {
        serviceComponents,
        isSuccess,
        isError,
        setIsSuccess,
        setIsError,
        setIsComponentLoading,
        isComponentsLoading,
    } = usePopupStore();


    const selectedService = Services?.find((item) => item.ServiceUuid === serviceUuid);

    const selectedServiceComponent = serviceComponents?.find((item) => item.ServiceComponentUuid === serviceComponentUuid);

    const incidentDescription = phoneNumberValue.length > 1 ? `${textareaValue}\nКонтактный номер телефона: ${phoneNumberValue}` : textareaValue;

    const descriptionParts = [
        textareaValue,
        phoneNumberValue?.length > 1 ? phoneNumberValue : '',
        selectedType?.value === 'Удалённо'
            ? 'Местоположение: Удалённо'
            : selectedCity?.value && selectedPlace?.value
                ? `Местоположение: ${selectedCity.value} — ${selectedPlace.value}`
                : ''
    ];


    const description = descriptionParts.filter(Boolean).join('\n');

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsComponentLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mobiledatag/addNewIncedentM`,
                {
                    Topic: selectedServiceComponent?.ServiceComponent,
                    Data: "12.12.2023 12:13:14",
                    Description: description,
                    MembershipServices: {
                        UID: serviceComponentUuid,
                        Service: {
                            UID: serviceUuid
                        }
                    },
                    Files: filesArray
                },
                {
                    headers: getAuthHeaders()
                });
            setIsSuccess(true);
        } catch (error) {
            console.log(error);
            setIsError(true);
        } finally {
            setCurrentStep(3);
            setIsComponentLoading(false);
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', marginTop: '15px' }}>
            <motion.form
                initial={blockTransition.initial}
                animate={blockTransition.animate}
                exit={blockTransition.exit}
                transition={blockTransition.transition}
                className={`popup__form popup__form${colorSchemeClassName}`}
                onSubmit={handleFormSubmit}
            >
                <div className="popup__form__text-block">
                    <p style={{ fontWeight: 'bold' }}>{`${selectedService?.Service} — ${selectedServiceComponent?.ServiceComponent}`}</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', gap: '10px'}}>
                    <p>Ваше местоположение</p>
                    <div className="popup__form__select-block">

                        <DefaultSelect
                            placeholder={'Удалённо или офис/объект '}
                            isSearchable={false}
                            options={PhysicalOrRemote}
                            required={true}
                            onChange={setSelectedType}
                            value={selectedType}
                        />
                        <DefaultSelect
                            placeholder={'Выберите город'}
                            isSearchable={false}
                            options={cities}
                            required={true}
                            onChange={handleCityChange}
                            disabled={!selectedType?.value || selectedType?.value === 'Удалённо'}
                            value={selectedCity}
                        />
                        <DefaultSelect
                            placeholder={'Выберите локацию'}
                            isSearchable={false}
                            options={placesByCity[selectedCity?.value] || []}
                            required={true}
                            onChange={handlePlaceChange}
                            value={selectedPlace}
                            disabled={!selectedCity?.value}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '100%', height: '100%', gap: '10px' }}>
                    <p>Описание</p>
                    <textarea
                        className={`popup__textarea popup__textarea${colorSchemeClassName}`}
                        placeholder="Подробно опишите, с чем Вам нужна помощь..."
                        required
                        value={textareaValue}
                        onChange={(e) => setTextareaValue(e.target.value)}
                    >
                    </textarea>
                </div>
                <PhoneInput setPhoneNumber={setPhoneNumberValue} phoneNumberValue={phoneNumberValue} />
                <div className="popup__form__button-block">
                    <StyledDropzone setFilesArray={setFileBase64List} />
                    <button className="popup__form__submit-button" type="submit" disabled={isComponentsLoading}>Отправить</button>
                </div>
            </motion.form>
        </div>
    );
}

export default LocationForm;
