import { useEffect } from 'react';
import './App.css';
import Header from './components/Header/Header';
import useThemeStore from './stores/useThemeStore';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PrivateRoutes from './utils/ProtectedRoute';
import LoginPage from './pages/LoginPage/LoginPage';
import useAuthStore from './stores/useAuthStore';
import IncidentsPage from './pages/IncidentsPage/IncidentsPage';
import WorkSpacePage from './pages/WorkSpacePage/WorkSpacePage';
import KnowledgePage from './pages/KnowledgePage/KnowledgePage';
import useOptionsStore from './stores/useOptionsStore';
import IncidentPopupDesktop from './pages/IncidentsPage/IncidentPopup/IncidentPopupDesktop';
import IncidentsPageMobile from './pages/IncidentsPage/IncidentPageMobile';
import AuthPage from './pages/AuthPage/AuthPage';
import NotificationToast from './modules/NotificationModule/NotificationToast';
import NotificationModule from './modules/NotificationModule/NotificationModule';
import DefaultSelect from './components/Select/Select';
import AuthTgPage from './pages/AuthTgPage/AuthTgPage';
import axios from 'axios';
import Cookies from 'js-cookie';
function App() {
  const { changeColorScheme, getColorScheme, colorSchemeClassName } = useThemeStore();
  const { checkAndSetOptions, isMobile } = useOptionsStore();
  const location = useLocation();
  const auth = useAuthStore();
  const previousLocation = location.state?.previousLocation;
  const currentColorScheme = getColorScheme();
  const navigate = useNavigate();
  // Обновление мета-тега theme-color при изменении темы
  useEffect(() => {
    const themeColorMetaTag = document.querySelector('#theme-color-meta');
    let newThemeColor;
    if (location.pathname === '/sign-in') {
      newThemeColor = currentColorScheme === 'dark' ? '#2E2E2E' : '#ffffff';
    } else if (location.pathname === '/incidents' && location.search.includes('popup=true')) {
      newThemeColor = currentColorScheme === 'dark' ? '#252525' : '#ffffff';
    } else {
      newThemeColor = currentColorScheme === 'dark' ? '#121212' : '#ffffff';
    }

    if (themeColorMetaTag) {
      themeColorMetaTag.setAttribute('content', newThemeColor);
    }
  }, [currentColorScheme, location]);

  // Обновление состояния isMobile при изменении ширины экрана
  useEffect(() => {
    const handleResize = () => {
      useOptionsStore.getState().setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Инициализация при монтировании
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Обновление системной темы при монтировании компонента
  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const systemColorScheme = darkModeMediaQuery.matches ? 'dark' : 'light';
    changeColorScheme('systemScheme', systemColorScheme);
  }, [changeColorScheme]);

  axios.interceptors.response.use(
    (response) => response, // Успешные ответы пропускаем как есть
    (error) => {
      if (error.response && error.response.status === 401) {
        // Обрабатываем ошибку 401
        console.error("Unauthorized access. Redirecting to login...");
        // Удаляем токен из куки
        Cookies.remove('authToken');
        Cookies.remove('user');
        navigate('/sign-in');
        // Перенаправляем пользователя на страницу логина
         // или используйте роутер, если это SPA
      }
      return Promise.reject(error);
    }
  );

  // Настройки анимации для переходов между страницами
  const mobilePageTransition = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
    transition: { duration: 0.4, type: 'spring' },
  };

  const desktopPageTransition = {
    initial: { opacity: 0, y: -50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 50 },
    transition: { duration: 0.3, type: 'tween' },
  };

  const pageTransition = isMobile ? mobilePageTransition : desktopPageTransition;

  return (
    <>
      <div className={`App App_${colorSchemeClassName}`}>
        {auth.isLoggedIn && <Header />}
        <AnimatePresence mode='wait'>
          <Routes location={previousLocation || location} key={location.pathname}>
            <Route
              path="/sign-in"
              element={
                <motion.div
                  className="page-container"
                  id='login-page-containter'
                  initial={pageTransition.initial}
                  animate={pageTransition.animate}
                  exit={pageTransition.exit}
                  transition={pageTransition.transition}
                >
                  {auth.isLoggedIn ? <Navigate to="/incidents" /> : <LoginPage />}
                </motion.div>
              }
            />
            <Route
              path="/telegram-auth"
              element={
                <motion.div
                  className="page-container"
                  id='tg-login-page-containter'
                  initial={pageTransition.initial}
                  animate={pageTransition.animate}
                  exit={pageTransition.exit}
                  transition={pageTransition.transition}
                >
                  <AuthTgPage />
                </motion.div>
              }
            />
            <Route element={<PrivateRoutes />}>
              <Route
                path="/workspace/*"
                element={
                  <motion.div
                    className="page-container"
                    initial={pageTransition.initial}
                    animate={pageTransition.animate}
                    exit={pageTransition.exit}
                    transition={pageTransition.transition}
                  >
                    <WorkSpacePage />
                  </motion.div>
                }
              />
              <Route
                path="/incidents"
                element={
                  <motion.div
                    className="page-container"
                    initial={pageTransition.initial}
                    animate={pageTransition.animate}
                    exit={pageTransition.exit}
                    transition={pageTransition.transition}
                  >
                    {isMobile ? <IncidentsPageMobile /> : <IncidentsPage />}
                  </motion.div>
                }
              >
                <Route path="service-modal/:serviceUuid" element={<IncidentPopupDesktop />} />
              </Route>
              <Route
                path="/knowledge"
                element={
                  <motion.div
                    className="page-container"
                    initial={pageTransition.initial}
                    animate={pageTransition.animate}
                    exit={pageTransition.exit}
                    transition={pageTransition.transition}
                  >
                    <KnowledgePage />
                  </motion.div>
                }
              />
              {/* <Route
                path="/notifications"
                element={
                  <motion.div
                    className="page-container"
                    initial={pageTransition.initial}
                    animate={pageTransition.animate}
                    exit={pageTransition.exit}
                    transition={pageTransition.transition}
                  >
                    <NotificationModule />
                  </motion.div>
                }
              /> */}
            </Route>
            <Route
              path="/params"
              element={
                <motion.div
                  className="page-container"
                  initial={pageTransition.initial}
                  animate={pageTransition.animate}
                  exit={pageTransition.exit}
                  transition={pageTransition.transition}
                >
                  <AuthPage />
                </motion.div>
              }
            />
            <Route
              path="*"
              element={
                <motion.div
                  className="page-container"
                  initial={pageTransition.initial}
                  animate={pageTransition.animate}
                  exit={pageTransition.exit}
                  transition={pageTransition.transition}
                >
                  <Navigate to={auth.isLoggedIn ? '/incidents' : '/sign-in'} />
                </motion.div>
              }
            />
          </Routes>
        </AnimatePresence>
      </div>
      <NotificationToast />
    </>
  );
}

export default App;
